export default function AdditionalInfo() {
  return (
    <div className="additional-info">
      <p>
        Valor das quadras: <strong>R$60,00</strong>
        <p>Pagamento deve ser realizado no local.</p>
      </p>
      <p>
        Não cobramos aluguel de raquetes e bolinhas (6 bolinhas por quadra).
      </p>
      <p>
        Todo <strong> SÁBADO e DOMINGO</strong>, reservas das 8h às 13h -{" "}
        <strong>DAY USE</strong> das 16h às 21:30h.
      </p>
      <p>
        <strong>DAY USE</strong> - R$20,00 (grátis para alunos matriculados) -
        Retirar a pulseira no caixa.
      </p>
    </div>
  );
}
